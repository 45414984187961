<template>
    <div>
        <div class="text-center my-3 row timer" v-if="live">
            <div class="col" v-if="days > 0">
                <div class="days count-element shadow-sm">{{ days }}</div>
                <div
                    class="text-uppercase"
                    style="font-size: 12px; text-align: center"
                >
                    Days
                </div>
            </div>
            <div class="col">
                <div class="hours count-element shadow-sm">{{ hours }}</div>
                <div
                    class="text-uppercase"
                    style="font-size: 12px; text-align: center"
                >
                    Hours
                </div>
            </div>
            <div class="col">
                <div class="minutes count-element shadow-sm">{{ minutes }}</div>
                <div
                    class="text-uppercase"
                    style="font-size: 12px; text-align: center"
                >
                    Minutes
                </div>
            </div>
            <div class="col">
                <div class="seconds count-element shadow-sm">{{ seconds }}</div>
                <div
                    class="text-uppercase"
                    style="font-size: 12px; text-align: center"
                >
                    Seconds
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        date: { type: String, required: true },
    },
    data() {
        return {
            past: false,
            live: false,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            var x = setInterval(
                function () {
                    var now = new Date().getTime();
                    var distance = new Date(this.date).getTime() - now;

                    if (distance < 0) {
                        this.live = false;
                        clearInterval(x);
                    } else {
                        this.days = Math.floor(
                            distance / (1000 * 60 * 60 * 24)
                        );
                        this.hours = Math.floor(
                            (distance % (1000 * 60 * 60 * 24)) /
                                (1000 * 60 * 60)
                        );
                        this.minutes = Math.floor(
                            (distance % (1000 * 60 * 60)) / (1000 * 60)
                        );
                        this.seconds = Math.floor(
                            (distance % (1000 * 60)) / 1000
                        );

                        this.live = true;
                    }
                }.bind(this),
                1000
            );
        },
    },
};
</script>
