<template>
    <div>
        <div class="card bg-light border border-danger shadow-sm">
            <div class="bg-danger p-3 rounded">
                <h3 class="text-center text-white">
                    <i class="fa fas fa-sms fa-fw fa-2x"></i><br />
                    Yes, Please Text An Event Reminder &amp; Agenda
                </h3>
            </div>
            <div
                class="card-body pb-0"
                :class="valid == false ? `was-validated` : ``"
            >
                <div class="form-group" v-if="!saved">
                    <input
                        type="text"
                        name="phone"
                        placeholder="Cell Phone #"
                        value=""
                        v-model="number"
                        class="form-control"
                        required
                    />
                    <div class="invalid-feedback">
                        Please provide a valid cell phone number
                    </div>
                </div>

                <div class="form-group" v-if="!saved">
                    <button
                        @click="storeCellNumber"
                        class="btn btn-block btn-lg btn-danger"
                    >
                        <span v-if="!saving">
                            <i class="fa fas fa-check-circle fa-fw"></i>
                            Subscribe
                        </span>
                        <span v-if="saving"
                            ><i class="fa fas fa-circle-notch fa-spin"></i
                        ></span>
                    </button>
                </div>
                <div v-if="saved">
                    <div class="alert alert-success">
                        <i class="fa fas fa-check-circle fa-fw"></i> You have
                        successfully subscribed to receive a reminder about the
                        upcoming event.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        cid: { type: String, required: true }
    },
    data: function() {
        return {
            saving: false,
            saved: false,
            valid: null,
            number: ""
        };
    },
    mounted() {
        this.checkForNumber();
    },
    methods: {
        checkForNumber() {
            axios.get(`/api/cell-phone/${this.cid}`).then(
                function(r) {
                    if (r.data.haveNumber) {
                        this.saving = false;
                        this.saved = true;
                    }
                }.bind(this)
            );
        },
        storeCellNumber() {
            if (this.number.length >= 10) {
                this.saving = true;

                axios
                    .post("/api/cell-phone/save", {
                        cid: this.cid,
                        number: this.number
                    })
                    .then(
                        function(r) {
                            this.saving = false;
                            this.saved = true;
                        }.bind(this)
                    );
            } else {
                this.valid = false;
                this.saving = false;
            }
        }
    }
};
</script>
