import analytics from './src/analytics';
import cc from './src/cc';
import geolocation from './src/geolocation';

const crypto = require('crypto');
const urlParse = require('url');

export default {
    analytics: analytics,
    cc: cc,
    init: function () {
        this.analytics.init();
        geolocation.init();
    },
    validation: function () {
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    },
    officeMap: function () {
        var map;
        var officeLatLng = new google.maps.LatLng(44.0766363, -79.4317382);
        var mapElement = document.getElementById('map');
        map = new google.maps.Map(mapElement, {
            center: officeLatLng,
            zoom: 16,
            disableDefaultUI: true,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [{
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#f5f5f5"
                    }]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [{
                        "visibility": "off"
                    }]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#616161"
                    }]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                        "color": "#f5f5f5"
                    }]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#bdbdbd"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#eeeeee"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#757575"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#e5e5e5"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#9e9e9e"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#ffffff"
                    }]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#757575"
                    }]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#dadada"
                    }]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#616161"
                    }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#9e9e9e"
                    }]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#e5e5e5"
                    }]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#eeeeee"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{
                        "color": "#c9c9c9"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                        "color": "#9e9e9e"
                    }]
                }
            ]
        });

        var marker = new google.maps.Marker({
            position: officeLatLng,
            map: map,
            title: 'Craig Proctor Coaching'
        });

        var infowindow = new google.maps.InfoWindow({
            content: "<h5 style='margin:0 0 10px 0;'>Craig Proctor Coaching &amp; Seminars</h5>1111 Stellar Drive, Unit #12<br>Newmarket, ON L3Y 7B8<br>PHONE: (800) 538-1034<br>FAX: (905) 830-1374",
            maxWidth: 300,
            minHeight: 300
        });
        infowindow.open(map, marker);
    },
    base64: {
        encode: function (data, key) {
            return crypto.createHmac('sha1', key).update(data).digest('base64');
        },
        decode: function (code) {
            return Buffer.from ? Buffer.from(code, 'base64') : new Buffer(code, 'base64');
        },
        removeWebSafe: function (safeEncodedString) {
            return safeEncodedString.replace(/-/g, '+').replace(/_/g, '/');
        },
        makeWebSafe: function (encodedString) {
            return encodedString.replace(/\+/g, '-').replace(/\//g, '_');
        },
        sign: function (path, secret) {
            const uri = urlParse.parse(path);

            const safeSecret = this.decode(this.removeWebSafe(secret));
            const hashedSignature = this.makeWebSafe(this.encode(uri.path, safeSecret));

            return urlParse.format(uri) + '&signature=' + hashedSignature;
        }
    },
    autocomplete: function () {
        var btn = document.getElementsByClassName('submitBtn')[0];
        var autocompleteField = document.getElementById('autocomplete');
        if (typeof (autocompleteField) != 'undefined' && typeof (google) != 'undefined' && autocomplete != null) {
            if (typeof (btn) !== 'undefined') {

            }
            var autocomplete;
            var addressForm = {
                street_number: 'short_name',
                route: 'short_name',
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'short_name',
                postal_code: 'short_name'
            };

            autocomplete = new google.maps.places.Autocomplete(autocompleteField, {
                types: ['geocode']
            });


            autocomplete.addListener('place_changed', function () {
                var place = autocomplete.getPlace();

                document.getElementById('lat').value = place.geometry.location.lat();
                document.getElementById('lng').value = place.geometry.location.lng();

                for (var component in addressForm) {
                    var element = document.getElementById(component);
                    if (typeof (element) != 'undefined' && element != null) {
                        element.value = '';
                        element.disabled = false;
                        for (var i = 0; i < place.address_components.length; i++) {
                            var addressType = place.address_components[i].types[0];

                            if (addressForm[addressType]) {
                                var elementAgain = document.getElementById(addressType);
                                if (typeof (elementAgain) != 'undefined' && elementAgain != null) {
                                    var val = place.address_components[i][addressForm[addressType]];
                                    elementAgain.value = val;
                                    elementAgain.parentElement.classList.remove('d-none');
                                }
                            }
                        }
                    }
                }
                if (typeof (autocompleteField) != 'undefined' && autocompleteField != null) {
                    autocompleteField.value = place.address_components[0]['short_name'] + ' ' + place.address_components[1]['short_name'];
                }
            });
        }
    }
}
