<template>
    <div>
        <div class="card mt-3 bg-light shadow-sm">
            <div class="card-body pb-0">
                <h3 class="text-primary text-center">
                    <i class="fa fas fa-check fa-fw"></i> Yes, Please Send Me
                    the Market Domination Checklist
                </h3>
                <p class="text-center">
                    <img
                        src="/images/download-offer/Market-Domination-Checklist-1.png"
                        class="w-75"
                    />
                </p>
                <div v-if="!saved">
                    <div class="form-group">
                        <label for="street">Street Address</label>
                        <input
                            type="text"
                            name="street"
                            autocomplete="off"
                            placeholder="Street Address"
                            class="form-control"
                            v-model="address.street"
                        />
                        <div class="invalid-feedback">
                            Please provide a valid street address
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="unit">Unit/Suite #</label>
                        <input
                            type="text"
                            name="unit"
                            placeholder="Unit / Suite #"
                            class="form-control"
                            v-model="address.unit"
                        />
                    </div>
                    <div class="form-group">
                        <label for="city">City</label>
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            class="form-control"
                            id="locality"
                            v-model="address.city"
                            required
                        />
                        <div class="invalid-feedback">
                            Please provide a valid city
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="state">State</label>
                        <select
                            name="state"
                            class="form-control"
                            id="administrative_area_level_1"
                            required
                            v-model="address.state"
                        >
                            <optgroup
                                v-for="(c, key) in filteredCountries"
                                v-if="c.states"
                                :key="key"
                                :label="c.name"
                            >
                                <option
                                    :value="s.abbr"
                                    v-for="s in c.states"
                                    :key="s.id"
                                    >{{ s.name }}</option
                                ></optgroup
                            >
                        </select>
                        <div class="invalid-feedback">
                            Please provide a valid state
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="country">Country</label>
                        <select
                            name="country"
                            class="form-control"
                            id="country"
                            v-model="address.country"
                            required
                        >
                            <option
                                :value="c.abbr"
                                v-for="c in country"
                                :key="c.id"
                                >{{ c.name }}</option
                            >
                        </select>
                        <div class="invalid-feedback">
                            Please provide a valid country
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="zip">Zip Code</label>
                        <input
                            type="text"
                            name="zip"
                            placeholder="Zip Code"
                            class="form-control"
                            id="postal_code"
                            v-model="address.zip"
                            required
                        />
                        <div class="invalid-feedback">
                            Please provide a valid zip code
                        </div>
                    </div>
                    <div class="form-group">
                        <button
                            class="btn btn-lg btn-block btn-success submitBtn"
                            @click="storeAddress()"
                        >
                            <span v-if="!saving"
                                ><i class="fa fas fa-envelope fa-fw"></i> Send
                                Now</span
                            >
                            <span v-if="saving"
                                ><i class="fa fas fa-circle-notch fa-spin"></i
                            ></span>
                        </button>
                    </div>
                </div>
                <div v-if="saved">
                    <div class="alert alert-success">
                        <i class="fa fas fa-check-circle fa-fw"></i> You have
                        successfully subscribed to receive market domination
                        checklist
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        cid: { type: String, required: true }
    },
    data: function() {
        return {
            saving: false,
            saved: false,
            valid: null,
            states: [],
            country: [],
            address: {
                street: "",
                unit: "",
                city: "",
                state: "",
                country: "",
                zip: ""
            }
        };
    },
    computed: {
        filteredCountries: function() {
            this.country.filter(
                function(c) {
                    if (c.id == 38 || c.id == 231) {
                        c["states"] = [];
                        this.states.filter(function(s) {
                            if (s.country_id == c.id) {
                                c.states.push(s);
                            }
                        });
                    }
                }.bind(this)
            );
            return this.country;
        }
    },
    mounted() {
        this.getStates();
        this.getCountry();
        this.checkForAddress();
    },
    methods: {
        checkForAddress() {
            axios.get(`/api/address/${this.cid}`).then(
                function(r) {
                    if (r.data.haveNumber) {
                        this.saving = false;
                        this.saved = true;
                    }
                }.bind(this)
            );
        },
        storeAddress() {
            this.saving = true;
            axios
                .post(`/api/address/save`, { cid: this.cid, address: this.address })
                .then(
                    function(r) {
                        if (r.status == 200) {
                            this.saving = false;
                            this.saved = true;
                        }
                    }.bind(this)
                );
        },
        getStates() {
            axios.get(`/api/address/states`).then(
                function(r) {
                    if (r.data) {
                        this.states = r.data;
                    }
                }.bind(this)
            );
        },
        getCountry() {
            axios.get(`/api/address/countries`).then(
                function(r) {
                    if (r.data) {
                        this.country = r.data;
                    }
                }.bind(this)
            );
        }
    }
};
</script>
