export default {
    init: function () {
        // if ("geolocation" in navigator) {
        //     navigator.geolocation.getCurrentPosition(this.showPosition);
        // }
    },

    showPosition: function(pos) {
        var lat = pos.coords.latitude;
        var lng = pos.coords.longitude;
    }
}