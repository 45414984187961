<template>
    <div>
        <div v-bind:id="vid" class="text-center">
            <div class="spinner-grow text-primary" role="status"></div>
            Loading Video...
        </div>
    </div>
</template>
<script>

    export default {
        props: {
            file : { type: String, required: true },
            vid: { type: String, required: true },
            autostart : { type: Boolean, default : false },
            poster: { type: String, default : '/images/default-poster.jpg'}
        },
        mounted() {
            jwplayer(this.vid).setup({
                autostart : this.autostart,
                file: this.file,
                image: this.poster
            });
        }
    }
</script>
