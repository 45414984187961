/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import craigproctor from './craigproctor';


window.craigproctor = craigproctor;
craigproctor.init();
window.Vue = require('vue');
window.moment = require('moment-timezone');
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('live-reg', require('./components/LiveReg.vue').default);
Vue.component('event-countdown', require('./components/EventCountdown.vue').default);
Vue.component('videoplayer', require('./components/VideoPlayer.vue').default);
Vue.component('gmap', require('./components/Map.vue').default);
Vue.component('book-apt', require('./components/BookApt.vue').default);
Vue.component('book-apt-embed', require('./components/BookAptEmbed.vue').default);
Vue.component('cell-phone', require('./components/CellPhone.vue').default);
Vue.component('address-capture', require('./components/AddressCapture.vue').default);


// const files = require.context('./', true, /\.vue$/i)

// files.keys().map(key => {
//     return Vue.component(_.last(key.split('/')).split('.')[0], files(key))
// })

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});

window.addEventListener('load', craigproctor.validation, false);
window.addEventListener('load', craigproctor.autocomplete, false);
