<template>
    <div>
       <div v-bind:id="mapId" :class="underlay ? 'map' : 'mb-3 card shadow-sm'" style="height:350px;"></div>
    </div>
</template>

<script>
    export default {
        props: ['loc','hotel','city','address','underlay'],
        data() {
            return {
                mapId: "map-"+Math.floor((Math.random() * 99999999) + 1),
                geocoder : new google.maps.Geocoder(),
                info: new google.maps.InfoWindow(),
                latLng : false,
                map: false
            };
        },
        mounted() {

            if(this.loc) {
                this.map = new google.maps.Map(document.getElementById(this.mapId));
                this.geocode();
                this.render();
                this.infoWindow();
            }
        },
        methods: {
            geocode: function(){
                if(this.map) {
                    this.geocoder.geocode({address:this.loc},function(results, status){
                        this.latLng = results[0].geometry.location;
                        this.map.setCenter(this.latLng);
                        this.info.setPosition(this.latLng);
                    }.bind(this));
                }
            },
            infoWindow: function(){
                 if(this.city && this.hotel && this.address && this.info) {
                    this.info.setContent(`<div style='font-size:14px;width:250px;'>
                        <h4 style='margin-top:0px;'><strong>`+this.hotel+` - `+this.city+`</strong></h4>
                        <p style='margin:0px;'>`+this.address+`</p>
                    </div>`);
                    this.info.open(this.map);
                }
            },
            render: function(){
                this.map.setOptions({
                    maxZoom: 16,
                    minZoom: 5,
                    zoom: 14,
                    disableDefaultUI: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                });
            }
        }
    }
</script>
