import cookie from './cookie';

export default {
    cookie: cookie,
    utmShorts : [
        { short: 'utmcsr', long: 'utm_source' },
        { short: 'utmcmd', long: 'utm_medium' },
        { short: 'utmccn', long: 'utm_campaign' },
        { short: 'utmcct', long: 'utm_content' },
    ],
    currentUTMs: {},
    landingPage:false,
    getParameterByName: function(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    getUTMparam: function() {
        for (var i = 0; i < this.utmShorts.length; i++) {
            var paramValue = this.getParameterByName(this.utmShorts[i].long);
            if (paramValue !== "") {
                this.currentUTMs[this.utmShorts[i].short] = paramValue;
            }
        }
    },
    getItemLength: function(item){
        var count = 0, i;

        if (!!item && item.constructor === Object) {
            for (i in item) {
                if (item.hasOwnProperty(i)) {
                    count++;
                }
            }
        } else if (!!item && item.constructor === Array) {
            for (i = 0; i < item.length; i++) {
                count++;
            }
        }

        return count;
    },
    createCookie: function() {
        var cookieData = [];

        for (var k in this.currentUTMs){
            if (this.currentUTMs.hasOwnProperty(k)) {
                cookieData.push(k + "=" + this.currentUTMs[k]);
            }
        }
        this.cookie.create("cpTracking",cookieData.join("|"),30);
    },

    readCookie: function() {
        var cookieData = this.cookie.read('cpTracking');
        if(cookieData) {
            cookieData.split('|').forEach(function(data){
                data = data.split('=');
                this.currentUTMs[data[0]] = data[1];
            }.bind(this));
        } else if(document.referrer) {
            this.currentUTMs['utmcsr'] = document.referrer;
            this.currentUTMs['utmcmd'] = '(referrer)';
            this.currentUTMs['utmccn'] = '(referrer)';
            this.currentUTMs['utmcct'] = '(none)';
        } else {
            this.currentUTMs['utmcsr'] = '(direct)';
            this.currentUTMs['utmcmd'] = '(none)';
            this.currentUTMs['utmccn'] = '(direct)';
            this.currentUTMs['utmcct'] = '(none)';
        }

    },
    makeUniqueId: function () {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (var i = 0; i < 32; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	},
    populateForms: function() {
        var forms = document.getElementsByTagName('form');
        if(forms) {
            for(var form of forms) {
                for(var key in this.currentUTMs) {
                    var input = document.createElement('input');
                    input.setAttribute("type", "hidden");
                    input.setAttribute("name", key);
                    input.setAttribute("value", this.currentUTMs[key]);
                    form.appendChild(input);
                }
            }
        }
    },

    init: function(){
        if(!this.cookie.read('cpClientKey')) {
            this.cookie.create('cpClientKey',this.makeUniqueId(),30);
        }

        this.getUTMparam();

        if (this.getItemLength(this.currentUTMs) > 0) {
            this.createCookie();
        } else {
            this.readCookie();
        }
        this.populateForms();

        this.setLandingPage(window.location.href);
        this.cookie.create("cpLandingpage",this.landingPage,30);
        this.populateFormWithLandingPageData();
        this.triggerHit();
    },
    triggerHit: function(){
        if(this.landingPage) {
            var data = this.currentUTMs;
            data.landpage = this.landingPage;
            data.url = this.landingPage;
            data.status = "LANDING_PAGE";
            data.cpClientKey = this.cookie.read('cpClientKey');
            axios.post('/api/tracking/click',data);
        }
    },
    setLandingPage: function(url) {
        this.landingPage = url;
    },
    populateFormWithLandingPageData:function(){
        if(this.landingPage) {
            var forms = document.getElementsByTagName('form');
            if(forms) {
                for(var form of forms) {
                    var input = document.createElement('input');
                    input.setAttribute("type", "hidden");
                    input.setAttribute("name", 'landpage');
                    input.setAttribute("value",this.landingPage);
                    form.appendChild(input);
                }
            }
        }
    }
};