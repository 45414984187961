export default {
    cards : [
        {
          type: 'visa',
          patterns: [4],
          format: /(\d{1,4})/g,
          length: [13, 16],
          cvcLength: [3],
          luhn: true
        }, {
          type: 'mastercard',
          patterns: [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27],
          format: /(\d{1,4})/g,
          length: [16],
          cvcLength: [3],
          luhn: true
        }, {
          type: 'amex',
          patterns: [34, 37],
          format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
          length: [15],
          cvcLength: [3, 4],
          luhn: true
        }
    ],


    formatCardNumber: function(ele){

        var ele = document.getElementById(ele);
        if(ele) {
            ele.addEventListener('keypress',this.restrictNumbers.bind(this),true);
            ele.addEventListener('keypress',this.restrictCardNumber.bind(this),true);
            ele.addEventListener('keypress',this.formatCard.bind(this));
            ele.addEventListener('keydown',this.formatBackCard.bind(this));
            ele.addEventListener('keyup', this.setCardType.bind(this));
            ele.addEventListener('paste', this.reFormatCardNumber.bind(this));
            ele.addEventListener('change', this.reFormatCardNumber.bind(this));
            ele.addEventListener('input', this.reFormatCardNumber.bind(this));
            ele.addEventListener('input', this.setCardType.bind(this));
        }
    },
    cardType: function(num) {
        var _ref;
        if (!num) {
        return null;
        }
        return ((_ref = this.cardFromNumber(num)) != null ? _ref.type : void 0) || null;
    },

    luhnCheck : function(num) {
        var digit, digits, odd, sum, _i, _len;
        odd = true;
        sum = 0;
        digits = (num + '').split('').reverse();
        for (_i = 0, _len = digits.length; _i < _len; _i++) {
          digit = digits[_i];
          digit = parseInt(digit, 10);
          if ((odd = !odd)) {
            digit *= 2;
          }
          if (digit > 9) {
            digit -= 9;
          }
          sum += digit;
        }
        return sum % 10 === 0;
    },




    restrictNumbers: function(e) {
        var input;
        if (e.metaKey || e.ctrlKey) {
            return true;
        }
        if (e.which === 32) {
            return false;
        }
        if (e.which === 0) {
            return true;
        }
        if (e.which < 33) {
            return true;
        }
        input = String.fromCharCode(e.which);
        return !!/[\d\s]/.test(input);
    },

    restrictCardNumber: function(evt){
        var target = evt.currentTarget;
        var digit = String.fromCharCode(evt.which);
        if (!/^\d+$/.test(digit)) {
            return;
        }
        var value = (target.value+digit).replace(/\D/g,'');
        var card = this.cardFromNumber(value);

        if(card) {
            return value.length <= card.length[card.length.length - 1];
        } else {
            return value.length <= 16;
        }
    },
    cardFromNumber: function(number) {
        var card, pattern, ref, p, i, j, len, len2;
        number = (number + "").replace(/\D/g,'');
        for(i = 0, len = this.cards.length; i < len; i++) {
            card = this.cards[i];
            ref = card.patterns;
            for(j = 0, len2 = ref.length; j < len2; j++) {
                pattern = ref[j];
                p = pattern+"";
                if(number.substr(0, p.length) == p) {
                    return card;
                }
            }
        }
    },
    formatCard: function(e) {
        var target, card, digit, length, re, upperLength, value;
        var digit = String.fromCharCode(e.which);
        if (!/^\d+$/.test(digit)) {
            return;
        }
        target = e.currentTarget;
        value = target.value;
        card = this.cardFromNumber(value + digit);
        length = (value.replace(/\D/g,'') + digit).length;
        upperLength = 16;

        if(card) {
            upperLength = card.length[card.length.length - 1];
        }

        if(length >= upperLength) {
            return;
        }

        if(card && card.type === 'amex') {
            re = /^(\d{4}|\d{4}\s\d{6})$/;
        } else {
            re = /(?:^|\s)(\d{4})$/;
        }

        if(re.test(value)) {
            e.preventDefault();
            return setTimeout(function(){
                return target.value = value + ' ' + digit;
            });
        } else if(re.test(value + digit)) {
            e.preventDefault();
            return setTimeout(function(){
                return target.value = value + digit + " ";
            });
        }
    },

    formatBackCard : function(e) {
        var target, value;
        target = e.currentTarget;
        value = target.value;
        if (e.which !== 8) {
            return;
        }

        if (/\d\s$/.test(value)) {
            e.preventDefault();
            return setTimeout(function() {
                return target.value = value.replace(/\d\s$/, '');
            });
        } else if (/\s\d?$/.test(value)) {
            e.preventDefault();
            return setTimeout(function() {
                return target.value = value.replace(/\d$/, '');
            });
        }
    },
    setCardType: function(e) {
        var target, allTypes, card, cardType, val;
        target = e.currentTarget;
        val = target.value;
        cardType = this.cardType(val) || 'unknown';
        if (!target.classList.contains(cardType)) {
            allTypes = (function() {
                var _i, _len, _results;
                _results = [];
                for (_i = 0, _len = this.cards.length; _i < _len; _i++) {
                    card = this.cards[_i];
                    _results.push(card.type);
                }
                return _results;
            }.bind(this))();
            target.classList.remove('unknown');
            // target.classList.remove(allTypes.join(' '));
            target.classList.add(cardType);
            target.classList.toggle('identified', cardType !== 'unknown');
            return this.cardType(cardType);
        }
    },
    reFormatCardNumber: function(e) {
        var target = e.currentTarget;
        return setTimeout(function() {
            var value;
            value = target.value;
            value = this.replaceFullWidthChars(value);
            value = this.formatCardNumber(value);
            return value;
        }.bind(this));
    },
    replaceFullWidthChars : function(str) {
        var chars, chr, fullWidth, halfWidth, idx, value, _i, _len;
        if (str == null) {
          str = '';
        }
        fullWidth = '\uff10\uff11\uff12\uff13\uff14\uff15\uff16\uff17\uff18\uff19';
        halfWidth = '0123456789';
        value = '';
        chars = str.split('');
        for (_i = 0, _len = chars.length; _i < _len; _i++) {
          chr = chars[_i];
          idx = fullWidth.indexOf(chr);
          if (idx > -1) {
            chr = halfWidth[idx];
          }
          value += chr;
        }
        return value;
    }
}