<template>
  <div>
    <div class="py-3">
      <div class="h3 text-primary text-center" v-if="saving">
        <div class="spinner-grow text-primary" role="status"></div>Saving Consultation.... Please Wait
      </div>
      <div class="h3 text-primary text-center" v-if="loading">
        <div class="spinner-grow text-primary" role="status"></div>Loading Available Appointments...
      </div>
      <div v-if="dates && !selectedDate">
        <h1 class="text-primary">Please pick a date you would like for your 1-on-1 consultation</h1>
        <div class="row">
          <div class="col-sm-4 col-md-3 py-2" v-for="(date, idx) in dates" :key="(idx)">
            <button
              class="btn btn-block btn-lg btn-outline-primary"
              v-on:click="selectDate(date.format('YYYY-MM-DD'))"
            >{{ date.format('ddd, MMM Do') }}</button>
          </div>
        </div>
      </div>
      <div v-if="apts && selectedDate && !saved && !saving && !showLongForm">
        <h1
          class="text-primary"
        >Please select a time on {{selectedDate.format("ddd, MMM Do, YYYY") }} for your consultation</h1>
        <div class="row">
          <div class="col-sm-4 col-md-3 py-2" v-for="(apt, idx) in apts" :key="(idx)">
            <button
              class="btn btn-block btn-lg btn-outline-primary"
              v-on:click="selectTime(apt.toDate())"
            >{{ apt.format("h:mm A z") }}</button>
          </div>
        </div>
        <p>
          <button class="btn btn-info text-white" v-on:click="resetSys">
            <i class="fas fa-arrow-left"></i> Select another day
          </button>
        </p>
      </div>
      <div v-if="(saved && !saving)">
        <div class="text-center">
          <h1
            class="text-primary"
          >Congratulations {{ saved.contact }}, you are successfully booked in for {{ saved.date }}</h1>
          <p>
            <a
              :href="'/api/book-appointments/calendar/consult-confirmation-'+saved.id+'.ics'"
              class="btn btn-success"
            >
              <i class="fas fa-calendar-alt"></i> Add This Event To My Calendar
            </a>
          </p>
        </div>
      </div>
    </div>
    <div v-if="showLongForm">
      <div>
        <div class="row">
          <h3
            class="text-center text-primary mb-4"
          >You're almost done! - Just one more step before we book your consultation.</h3>
          <p>I help agents ...</p>
          <ul>
            <li>Generate Consistent and Profitable Business Through Implementation of Proven SYSTEMS for Lead Generation, Conversion and Client Presentations​</li>
            <li>Create Omnipresence in Your Marketplace so the Most Motivated Prospects are Compelled to Seek You Out and Want to do Business with You and Only You</li>
            <li>Scale Your Business to High 6 and Even 7 Figures by Showing You How to Leverage Yourself with People, Marketing and Technology</li>
          </ul>
          <p>Please fill out the following profile so we can see if you're a good fit</p>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>First Name</label>
              <input type="text" class="form-control" v-model="userData.first" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Last Name</label>
              <input type="text" class="form-control" v-model="userData.last" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Email</label>
              <input type="text" class="form-control" v-model="userData.email" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Cell Phone</label>
              <input type="text" class="form-control" v-model="userData.phone" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- # Transactions in the last 12 Months Field -->
            <div class="form-group">
              <label># Transactions in the last 12 Months</label>
              <input
                type="text"
                name="trans12Month"
                class="form-control"
                v-model="userData.longForm.trans12Month"
              />
            </div>
          </div>

          <div class="col-md-6">
            <!-- Income in the last 12 Months Field -->
            <div class="form-group">
              <label>Income in the last 12 Months</label>
              <select
                name="income12Months"
                class="custom-select"
                v-model="userData.longForm.income12Months"
              >
                <option value>- Select One -</option>
                <option value="< $50,000">Less than $50,000</option>
                <option value="$50,000 - $100,000">$50,000 - $100,000</option>
                <option value="$100,000 - $200,000">$100,000 - $200,000</option>
                <option value="$200,000 - $500,000">$200,000 - $500,000</option>
                <option value="$500,000 - $1,000,000">$500,000 - $1,000,000</option>
                <option value="> $1,000,000">More than $1,000,000</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- AvgSalePrice Field -->
            <div class="form-group">
              <label>What is your average sale price</label>
              <input
                type="text"
                name="avgSalePrice"
                class="form-control"
                v-model="userData.longForm.avgSalePrice"
              />
            </div>
          </div>
          <div class="col-md-6">
            <!-- IncomeTarget Field -->
            <div class="form-group">
              <label>Your income target for this year</label>
              <input
                type="text"
                name="incomeTarget"
                class="form-control"
                v-model="userData.longForm.incomeTarget"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- SpentPerYear Field -->
            <div class="form-group">
              <label>$ spent/year on advertising</label>
              <input
                type="text"
                name="spentPerYear"
                class="form-control"
                v-model="userData.longForm.spentPerYear"
              />
            </div>
          </div>
          <div class="col-md-6">
            <!-- HoursWorked Field -->
            <div class="form-group">
              <label>Hours worked per week</label>
              <input
                type="text"
                name="hoursWorked"
                class="form-control"
                v-model="userData.longForm.hoursWorked"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!-- The number one problem you are having in your business right now Field -->
            <div class="form-group">
              <label>The number one problem you are having in your business right now</label>
              <textarea
                name="oneProblem"
                rows="5"
                class="form-control"
                v-model="userData.longForm.oneProblem"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- TeamSize Field -->
            <div class="form-group">
              <label>Size of your team</label>
              <select name="teamSize" class="custom-select" v-model="userData.longForm.teamSize">
                <option v-for="i in 20" :key="i">{{ i }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Your Role Field -->
            <div class="form-group">
              <label>Your Role</label>
              <select name="yourRole" class="custom-select" v-model="userData.longForm.yourRole">
                <option v-for="(v,i) in yourRole" :key="i">{{ v }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!-- YearCurrenctRole Field -->
            <div class="form-group">
              <label>Years in current role</label>
              <select
                name="yearCurrentRole"
                class="custom-select"
                v-model="userData.longForm.yearCurrentRole"
              >
                <option v-for="(r,i) in yearInCur" :key="i">{{ r }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!-- Other Real Estate Training You've Taken Field -->
            <div class="form-group">
              <label>Other Real Estate Training You've Taken</label>
              <textarea
                name="otherTraining"
                v-model="userData.longForm.otherTraining"
                rows="5"
                class="form-control"
              ></textarea>
            </div>
          </div>
        </div>

        <button
          @click="submitData"
          class="btn btn-lg btn-block"
          style="background-color:rgba(0,127,255,1);color:#fff;"
        >Submit</button>
      </div>
    </div>
  </div>
</template>
<script>
window.has = function(obj, key) {
  return key.split(".").every(function(x) {
    if (typeof obj != "object" || obj === null || !x in obj) return false;
    obj = obj[x];
    return true;
  });
};

export default {
  data: function() {
    return {
      yearInCur: [
        "Greater than 10 Years",
        "6-10 Years",
        "3-5 Years",
        "1-2 Years",
        "Less than 1 Year"
      ],
      yourRole: ["Broker/Manager", "Experienced Agent", "New Agent", "Other"],
      apts: false,
      selectedDate: false,
      showLongForm: false,
      dates: [],
      saved: false,
      loading: true,
      saving: false,
      userData: {
        first: null,
        last: null,
        email: null,
        phone: null,
        longForm: {
          trans12Month: null,
          income12Months: null,
          avgSalePrice: null,
          incomeTarget: null,
          spentPerYear: null,
          hoursWorked: null,
          oneProblem: null,
          teamSize: null,
          yourRole: null,
          yearCurrentRole: null,
          otherTraining: null
        },
        aptDate: null
      }
    };
  },
  mounted() {
    this.nextFiveDays();
  },
  methods: {
    resetSys: function() {
      this.apts = false;
      this.dates = [];
      this.selectedDate = false;
      this.nextFiveDays();
    },
    selectDate: function(d, e) {
      this.selectedDate = moment(d);
      this.getAvailableSlots();
    },
    selectTime: function(t, e) {
      Swal.fire({
        title: "Are you sure?",
        text:
          "Are you sure you want to select " +
          moment(t).format("ddd, MMM Do, YYYY h:mm A z"),
        type: "warning",
        showCancelButton: true
      }).then(
        function(willDelete) {
          if (willDelete.dismiss != "cancel") {
            this.userData.aptDate = t;
            this.showLongForm = true;
          } else {
            this.saving = false;
          }
        }.bind(this)
      );
    },
    submitData: function() {
      let error = false;

      Object.entries(this.userData).map(function(v, i) {
        if (v[1] == null || v[1] == "") error = true;
      });

      Object.entries(this.userData.longForm).map(function(v, i) {
        if (v[1] == null || v[1] == "") error = true;
      });
      if (!error) {
        this.saving = true;
        axios
          .post("/api/book-appointments/save-new", { userData: this.userData })
          .then(
            function(r) {
              this.saving = false;
              this.showLongForm = false;
              this.saved = {
                contact: r.data.contact,
                id: r.data.id,
                date: moment(r.data.date)
                  .tz(moment.tz.guess())
                  .format("ddd, MMM Do, YYYY h:mm A z")
              };
              setTimeout(function() {
                window.location =
                  "https://teamcraigproctor.com/thank-you-page1589432556148";
              }, 2000);
            }.bind(this)
          )
          .error(
            function(r) {
              this.saving = false;
              this.showLongForm = false;
              alert(r.error);
            }.bind(this)
          );
      } else {
        alert("All Fields are required");
      }
    },
    nextFiveDays: function() {
      this.loading = true;
      let dates = [];
      let today = moment();
      let i = 1;
      today.add(parseInt(1), "day");
      while (i <= 21) {
        if (today.day() != 0 && today.day() != 6) {
          dates.push(moment(today));
          i++;
        }
        today.add(parseInt(1), "day");
      }

      axios
        .post("/api/book-appointments/available-slots-between", {
          start: dates[0].format("YYYY-MM-DD"),
          end: dates[dates.length - 1].format("YYYY-MM-DD")
        })
        .then(
          function(r) {
            let tmp = [];
            dates.forEach(
              function(d, i) {
                if (r.data.slots.includes(d.format("YYYY-MM-DD"))) {
                  tmp.push(d);
                }
              }.bind(this)
            );
            this.dates = tmp;
            this.loading = false;
          }.bind(this)
        );
    },
    getAvailableSlots: function() {
      this.loading = true;
      axios
        .post("/api/book-appointments/available-slots", {
          date: this.selectedDate.format("YYYY-MM-DD")
        })
        .then(
          function(r) {
            if (r.status == 200) {
              this.apts = [];
              r.data.forEach(
                function(d) {
                  this.apts.push(moment(d.start).tz(moment.tz.guess()));
                }.bind(this)
              );
              this.loading = false;
            }
          }.bind(this)
        );
    }
  }
};
</script>

