<template>
  <div>
    <div class="py-3">
      <div class="h3 text-primary text-center" v-if="saving">
        <div class="spinner-grow text-primary" role="status"></div>Saving Consultation.... Please Wait
      </div>
      <div class="h3 text-primary text-center" v-if="loading">
        <div class="spinner-grow text-primary" role="status"></div>Loading Available Appointments...
      </div>
      <div v-if="dates && !selectedDate">
        <h1 class="text-primary">Please pick a date you would like for your 1-on-1 consultation</h1>
        <div class="row">
          <div class="col-sm-4 col-md-3 py-2" v-for="(date, idx) in dates" :key="idx">
            <button
              class="btn btn-block btn-lg btn-outline-primary"
              v-on:click="selectDate(date.format('YYYY-MM-DD'))"
            >{{ date.format('ddd, MMM Do, YYYY') }}</button>
          </div>
        </div>
      </div>
      <div v-if="apts && selectedDate && !saved && !saving">
        <h1
          class="text-primary"
        >Please select a time on {{selectedDate.format("ddd, MMM Do, YYYY") }} for your consultation</h1>
        <div class="row">
          <div class="col-sm-4 col-md-3 py-2" v-for="(apt,idx) in apts" :key="idx">
            <button
              class="btn btn-block btn-lg btn-outline-primary"
              v-on:click="selectTime(apt.toDate())"
            >{{ apt.format("h:mm A z") }}</button>
          </div>
        </div>
        <p>
          <button class="btn btn-info text-white" v-on:click="resetSys">
            <i class="fas fa-arrow-left"></i> Select another day
          </button>
        </p>
      </div>
      <div v-if="(saved && !saving)">
        <div class="text-center">
          <h1
            class="text-primary"
          >Congratulations {{ saved.contact }}, you are successfully booked in for {{ saved.date }}</h1>
          <p>
            <a
              :href="'/api/book-appointments/calendar/consult-confirmation-'+saved.id+'.ics'"
              class="btn btn-success"
            >
              <i class="fas fa-calendar-alt"></i> Add This Event To My Calendar
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cid: { type: String, required: true }
  },
  data: function() {
    return {
      apts: false,
      selectedDate: false,
      dates: [],
      firstDate: null,
      lastDate: null,
      saved: false,
      loading: true,
      saving: false
    };
  },
  mounted() {
    this.nextFiveDays();
  },
  methods: {
    resetSys: function() {
      this.apts = false;
      this.dates = [];
      this.selectedDate = false;
      this.nextFiveDays();
    },
    selectDate: function(d, e) {
      this.selectedDate = moment(d);
      this.getAvailableSlots();
    },
    selectTime: function(t, e) {
      Swal.fire({
        title: "Are you sure?",
        text:
          "Are you sure you want to book your consultation for " +
          moment(t).format("ddd, MMM Do, YYYY h:mm A z"),
        type: "warning",
        showCancelButton: true
      })
        .then(
          function(willDelete) {
            this.saving = true;
            if (willDelete.dismiss != "cancel") {
              axios
                .post("/api/book-appointments/save", { cid: this.cid, date: t })
                .then(
                  function(r) {
                    this.saving = false;
                    this.saved = {
                      contact: r.data.contact,
                      id: r.data.id,
                      date: moment(r.data.date)
                        .tz(moment.tz.guess())
                        .format("ddd, MMM Do, YYYY h:mm A z")
                    };
                  }.bind(this)
                );
            } else {
              this.saving = false;
            }
          }.bind(this)
        )
        .error(
          function(r) {
            this.saving = false;
            alert(r.error);
          }.bind(this)
        );
    },
    nextFiveDays: function() {
      let dates = [];
      var today = moment();
      var i = 1;
      today.add(parseInt(1), "day");
      while (i <= 21) {
        if (today.day() != 0 && today.day() != 6) {
          dates.push(moment(today));
          i++;
        }
        today.add(parseInt(1), "day");
      }

      axios
        .post("/api/book-appointments/available-slots-between", {
          start: dates[0].format("YYYY-MM-DD"),
          end: dates[dates.length - 1].format("YYYY-MM-DD")
        })
        .then(
          function(r) {
            let tmp = [];
            dates.forEach(
              function(d, i) {
                if (r.data.slots.includes(d.format("YYYY-MM-DD"))) {
                  tmp.push(d);
                }
              }.bind(this)
            );
            this.dates = tmp;
            this.loading = false;
          }.bind(this)
        );
    },
    getAvailableSlots: function() {
      this.loading = true;
      axios
        .post("/api/book-appointments/available-slots", {
          date: this.selectedDate.format("YYYY-MM-DD")
        })
        .then(
          function(r) {
            if (r.status == 200) {
              this.apts = [];
              r.data.forEach(
                function(d) {
                  this.apts.push(moment(d.start).tz(moment.tz.guess()));
                }.bind(this)
              );
              this.loading = false;
            }
          }.bind(this)
        );
    }
  }
};
</script>
