<template>
    <div>
        <transition name="live-reg-animation"
            enter-active-class="slideInUp"
            leave-active-class="slideOutDown"
            v-on:before-enter="beforeAnimatedIn"
            v-on:after-leave="afterAnimatedOut"
            v-on:enter="enteredScreen">
            <div id="liveReg" class="animated faster liveReg" v-if="show">
                <div class="liveRegBubble">
                    <img v-bind:src="user.mapImg" class="img circle float-left mr-2">
                    <div>
                        <h5 class="m-0 text-truncate">
                            <span class="name">{{ user.first }} {{ user.last }}</span> from <span class="loc">{{ user.city }}, {{ user.state }}</span>
                        </h5>
                        <p class="mb-2"><slot/></p>
                        <div style="margin-top:-5px;">
                            <span class="time">16 Hours ago</span>
                            <div class="badge-container">
                                <span class="badge badge-success" style="border-radius:15px;"><i class="fas fa-fw fa-check"></i> Verified by CPS</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                users: false,
                user: false,
                show: false,
                wait: 5000
            };
        },
        mounted() {
            this.getUsers();
        },
        methods: {
            beforeAnimatedIn: function(){
                this.getRandomUser();
            },
            afterAnimatedOut: function() {
                this.getRandomUser();
                setTimeout(function(){
                    this.show = true;
                }.bind(this),this.wait);
            },
            enteredScreen: function() {
                setTimeout(function(){
                    this.show = false;
                }.bind(this),this.wait);
            },
            getUsers: function() {
                axios.post('/api/events/recent-registrations').then(function(r){
                    if(r.status == 200) {
                        this.users = r.data;
                        setTimeout(function(){
                            this.show = true;
                        }.bind(this),this.wait);
                        // this.getRandomUser();
                    }
                }.bind(this));
            },
            getRandomUser:function(){
                this.user = this.users[Math.floor(Math.random() * this.users.length)];
            }
        }
    }
</script>
